* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app_logo {
  width: 100px;
  min-height: 100px;
}

.about_us {
  padding-bottom: 90px;
}

.app_logo_img {
  height: 190px;
  width: 190px;
}

.social_media_logo_01 {
  height: 40px;
  width: 40px;
  margin: 0px 20px;
}

.social_media_links_boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.form_heading_box {
  width: 140px;
  background-color: #a9e2ff;
  padding: 5px 10px 0px 10px;
  border-radius: 10px;
  margin-bottom: -20px;
  z-index: 1;
}

.form_heading {
  font-size: 20px;
}

.form_box_contact {
  background-color: #80C9EF;
  border-radius: 15px;
  width: 80%;
  height: auto;
}

.email_input {
  border: none;
  border-radius: 15px;
  width: 100%;
  padding: 8px 16px;
}

.message_input {
  border: none;
  border-radius: 15px;
  padding: 8px 16px;
}

.email_input_box_contact{
  width: 80%;
}

.submit_input {
  background-color: #e7f7ff;
  border: none;
  padding: 2px 20px;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .social_media_logo_01 {
    margin: 0px 15px;
  }
}

@media screen and (max-width: 768px) {
  .social_media_logo_01 {
    margin: 0px 10px;
  }

  .contact_us_box {
    padding-top: 70px;
  }

  .about_us {
    padding-bottom: 50px;
  }

  .form_box_contact {
    width: 90%;
  }
}
@media screen and (max-width: 550px) {
  .form_heading_box {
    width: 120px;
    padding: 5px 10px 0px 10px;
  }
  .form_heading {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .social_media_logo_01 {
    margin: 0px 15px;
  }

  .contact_us_box {
    padding-top: 70px;
  }

  .about_us {
    padding-bottom: 10px;
  }

  .form_box_contact {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .app_logo_img {
    height: 150px;
    width: 150px;
  }

  .social_media_logo_01 {
    height: 30px;
    width: 30px;
    margin: 0px 13px;
  }
}
