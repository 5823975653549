.monthly_box_recuring_main {
  background-color: #d3c5f1;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 14px;
  min-width: 150px;
}

.months_recurring {
  color: white;
}

.monthy_recurring_title {
  color: #a283e2;
}

.weeks_recurring {
  color: white;
}
