.checkbox_container1 {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  width: 5rem;
  color: #837195;
}

.checkbox_container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 4px;
}

.show_time {
  color: #a080e1;
  font-size: 14px;
  padding: 2px 4px;
  border: 1px solid #808080;
  border-radius: 4px;
}

.checkbox_container1 input:checked + .checkmark2 {
  background-color: #d2ebb5;
  border: none;
}

.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_container1 input:checked + .checkmark2:after {
  display: block;
}

.checkbox_container1 .checkmark2:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.addCleaner_main {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: auto;
}

.add_new {
  background-color: transparent;
  color: #a080e1;
  border: none;
  font-size: 14px;
}

.add_services_heading {
  font-size: 24px;
  font-weight: 600;
}

.img_task {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.btn_add_img {
  background-color: transparent;
  border: none;
  color: #a080e1;
}

.form_box {
  background-color: white;
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  min-height: 85vh;
  position: relative;
  padding-top: 40px;
  margin-bottom: 10px;
}
.add-services__add-button {
  margin-top: 5rem;
}

.btn_add_services {
  background-color: #a080e1;
  color: white;
  border: none;
  border-radius: 18px;
  padding: 4px 10px;
  font-size: 18px;
  position: absolute;
  bottom: 30px;
}

.btn_add_tasks {
  background-color: #a080e1;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 15px;
}
@media screen and (max-width: 1200px) {
  .img_task {
    width: 35px;
    height: 42px;
  }
  .custom_tasks {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .custom_tasks {
    width: 95%;
  }
  .form_box {
    height: auto;
  }
  .addCleaner_main {
    height: auto;
  }
  .add-services__add-button {
    margin-top: 6rem;
  }
}
