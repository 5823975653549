.input_signup01 {
  width: 400px;
  height: 40px;
  border: 1px solid rgba(66, 66, 66, 0.5);
  border-radius: 8px;
  padding: 0px 10px;
}

.signup_label_input01 {
  margin-top: 40px;
}

.option_week {
  padding: 20x 0px;
}

.option_select {
  font-weight: 700;
}

.select_numbers {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.custom-calendar {
  font-size: 16px;
}

.react-calendar__navigation {
  display: none;
}

.react-calendar__month-view__days {
  display: none;
}

.react-calendar__month-view__weekdays,
.react-calendar__tile--now,
.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  display: none;
}

.custom-calendar {
  font-size: 18px;
  border: 1px solid rgba(66, 66, 66, 0.5);
  border-radius: 8px;
  padding: 10px 2px;
  width: 400px;
}

.booking_options_heading {
  padding: 30px 0px 0px 20px;
}

.react-calendar__navigation {
  display: none;
}

.react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.react-calendar__tile {
  display: none;
}

.react-calendar__month-view__weekdays abbr {
  cursor: pointer;
  text-decoration: none;
  border-bottom: none;
  font-weight: 500;
}

.show_numbers_month {
  border: 1px solid rgba(66, 66, 66, 0.5);
  border-radius: 8px;
  width: 400px;
}

@media screen and (max-width: 1290px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 300px;
  }
}

@media screen and (max-width: 1010px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 270px;
  }
}

@media screen and (max-width: 991px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 300px;
  }
}

@media screen and (max-width: 425px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 270px;
  }

  .select_days_week_options {
    font-size: 14px;
  }

  .section_select_weeks{
    /* width: 100%; */
  }
}
@media screen and (max-width: 375px) {
  .days_week_booking_options {
    font-size: 11px;
  }
}
@media screen and (max-width: 350px) {
  .input_signup01,
  .custom-calendar,
  .show_numbers_month {
    width: 240px;
  }

  
}
