.remove_email {
  border-radius: 5px;
  width: 300px;
}

.change_email {
  color: #a080e1;
  font-weight: 200;
  font-size: 12px;
}

.btn_confirm {
  color: white;
  background-color: #a080e1;
  border: none;
  padding: 5px 10px 3px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 200;
}

.remove_heading {
  margin-left: 30px;
}

.btn_confirm_div {
  margin-top: 120px;
}

@media screen and (max-width: 768px) {
  .confirm_deletion_setting {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .confirm_deletion_setting {
    font-size: 12px;
  }
}
