.main {
  background-color: #d2ebb5;
  padding: 30px 30px !important;
}

.section_main {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 20px 10px;
}

.section_sub_main {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  height: 550px;
}
.main_upcoming_booking_cleaner_box05 {
  background-color: #f6f9f8 !important;
  width: 100% !important;
  margin-top: 20px;
  padding-top: 0px;
  margin-bottom: 20px;
  border-radius: 8px;
  height: 100vh;
  padding: 30px 30px !important;
}
.notification_heading {
  font-size: 23px;
  padding: 0 !important;
  margin: 0 !important;
}

.notification_hr {
  margin: 0 !important;
  padding: 0 !important;
}

.btn_view {
  color: #c6b3ed;
  background-color: transparent;
  border: none;
  font-size: small;
}

.notification_parg {
  font-size: 15px;
  font-weight: 300;
}

.main {
  width: 90%;
}

.cancel_pop {
  color: #a080e1;
  font-size: 22px;
}

.booking_detail {
  background-color: #ffffff;
}

.summary_box {
  border: 1px solid #837195;
  background-color: #ffffff;
  width: 98% !important;
  border-radius: 10px;
  padding: 30px 30px 30px !important;
}

.profile_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #a080e1;
  background-size: 100% 100%;
}

.location_icon_1 {
  width: 20px;
  height: 20px;
}
.location_icon_2 {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.cleaner_name {
  font-size: 24px;
  font-weight: 600;
}

.cleaning_title {
  font-size: 20px;
  color: #a080e1;
}

.service_icons {
  width: 40px;
  height: 40px;
}

.service_icons_title {
  font-size: 13px;
}

.location_detail {
  color: #837195;
}
.btn_ntf_cancel {
  background-color: transparent;
  border: none;
  color: #a080e1;
  font-size: 15px;
}
.btn_ntf_confirm {
  background-color: #a080e1;
  border: none;
  color: white;
  padding: 2px 14px;
  border-radius: 12px;
  font-size: 15px;
  margin-top: 5px;
}
.btn_box {
  margin-top: 90px !important;
}

.location_icon {
  width: 18px;
  height: 24px;
}

.icon_box_1 {
  padding: 0px 10px 0px;
}

.icon_box_2 {
  padding-right: 10px;
}

.time {
  font-size: 20px;
  color: #a080e1;
}

.product_not_needed {
  font-size: 10px;
  color: #837195;
}

.not_needed {
  margin-top: 80px;
}

.cleaner_name_service {
  display: flex;
  justify-content: flex-start;
}

.img_box {
  display: flex;
  justify-content: center;
}

.cancel_button {
  border: none;
  background-color: transparent;
  color: #a080e1;
}

.continue_to_payment {
  border: none;
  background-color: #a080e1;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  font-size: 13px;
}

.arrow {
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 1024px) {
  .summary_box {
    width: 100% !important;
  }
  .anything {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .cleaning_title {
    font-size: 14px;
  }

  .profile_img {
    width: 50px;
    height: 50px;
  }

  .price_tag {
    height: 30px;
    width: 40px;
  }

  .service_icons_main {
    justify-content: space-around;
  }

  .location_calendar_box {
    margin-top: 20px;
  }

  .location_detail {
    font-size: 14px;
  }
    .main_upcoming_booking_cleaner_box05{
      height: auto;
    }

  .not_needed {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .your_services_main {
    padding-top: 10px !important;
    margin-top: 20px;
    width: 90%;
  }

  .cleaner_name {
    margin-top: 20px;
  }

  .cleaner_name_service {
    justify-content: center;
  }

  .icon_box_1 {
    padding: 0px 00px 0px;
  }
  .section_sub_main {
    height: auto;
  }

  .location_detail {
    font-size: 16px;
  }

  .icon_box_2 {
    padding-right: 0px;
  }

  .main {
    width: 90%;
  }

  .summary_box {
    width: 85% !important;
  }

  .working_hours {
    margin-top: 20px;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }
  .completed_booking_notification {
    font-size: 20px;
  }
}

@media screen and (max-width: 425px) {
  .location_detail {
    font-size: 12px;
  }

  .your_services_main {
    padding: 0px;
  }

  .summary_box {
    padding: 30px 0px 30px 0px !important;
  }
  .continue_to_payment {
    margin-bottom: 20px;
  }
  .completed_booking_notification {
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .main {
    padding: 0px 0px !important;
  }

  .section_main {
    padding: 10px 0px;
  }

  .section_sub_main {
    padding: 10px 0px;
  }

  .completed_booking_notification {
    font-size: 15px;
  }
  .main_upcoming_booking_cleaner_box05 {
    padding: 20px 5px !important;
  }
}

@media screen and (max-width: 320px) {
  .main {
    padding: 0px 0px !important;
  }

  .section_main {
    padding: 10px 0px;
  }

  .section_sub_main {
    padding: 10px 0px;
  }
  .icon_box_1 {
    padding: 0px 0px 0px;
  }

  .icon_box_2 {
    padding-right: 0px;
  }
}
