.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  color: #837195;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 4px;
}
.show_time {
  color: #a080e1;
  font-size: 14px;
  padding: 2px 4px;
  border: 1px solid #808080;
  border-radius: 4px;
}

.checkbox-container input:checked + .checkmark {
  background-color: #d2ebb5;
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.btm_brd {
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}

.bookingCard-selected {
  background-color: #e0dbe4;
}

.cleaner_detail {
  border-bottom: 1px solid #e3e3e3 !important;
}
.customer_name_heading {
  padding: 0;
  margin: 0;
  font-size: 24px;
}
.anything01 {
  color: #837195;
}
.loc_detail {
  margin-left: 10px;
}

.checkbox-container input:checked + .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 425px) {
  .customer_name_heading {
    font-size: 16px;
  }

  .title_card {
    font-size: 12px;
  }
  .anything {
    font-size: 10px;
  }
  .anything01 {
    font-size: 11px !important;
  }

  .toogle_time {
    margin: 0px 10px !important;
  }
}
