.main_box_setting {
  padding: 10px;
}

.sub_main_setting {
  background-color: #f8f9fa;
  padding: 20px;
  min-height: 90vh;
  border-radius: 8px;
}

.main_content_setting {
  background-color: #ffffff;
  width: 98%;
  border-radius: 8px;
  padding: 15px;
  padding-top: 48px;
  min-height: 80vh;
}

.setting_links {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
}

.nothing {
  margin-top: 15px;
}

.f_half {
  border-right: 1px solid black;
}

.setting_links_main {
  height: 70vh;
  padding: 20px 0px 70px 12px;
}

.setting_links:active,
.setting_links:focus {
  color: #a080e1;
}

@media screen and (max-width: 767px) {
  .setting_links_main {
    height: auto;
    padding: 20px 0px 70px 12px;
  }

  .f_half {
    border-right: none;
  }

  .setting_links {
    font-size: 14px;
  }

  .main_content_setting {
    padding-top: 1rem !important;
  }

  .setting_links_main {
    padding: 20px 0px 20px 12px;
  }
}

@media screen and (max-width: 767px) {
  .heading_settings_main {
    font-size: 16px;
  }
}
