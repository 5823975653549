.service_list_admin_main {
  background-color: white;
  border-radius: 12px;
}

.service_title_admin {
  color: #a080e1;
  font-size: 18px;
}

.detail_style {
  color: #9182a2;
  font-size: 18px;
}

.detail_style01 {
  color: #9182a2;
  font-size: 24px;
}

.detail_style02 {
  color: #a080e1;
}

.icon_service_img {
  width: 30px;
  height: 25px;
}

.custom_tasks_admin_services{
    padding-right : 15px;
}

.service_title {
  font-size: 10px;
}

.btn_update {
  border: none;
  border-radius: 4px;
  background-color: #53b655;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
}

.delete {
  background-color: #d54b48;
}
