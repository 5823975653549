.display_profile01 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #a080e1;
}

.main_boking_options {
  border: none;
  background-color: #f8f9fa;
  height: 95vh;
  border-radius: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.main_boking_options::-webkit-scrollbar {
  width: 0px;
}

.main_boking_options::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.main_boking_options::-webkit-scrollbar-track {
  background-color: transparent;
}

.booking_upcoming_section_1 {
  border: 1px solid #9586a4;
}

.btn_select_customer {
  background-color: #a080e1;
  color: white;
  border: none;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
}

.price_tag01 {
  color: #a080e1;
  font-size: 35px;
  font-weight: 400;
}

.card_title01 {
  font-size: 26px;
  padding: 0 !important;
  margin: 0 !important;
}

.card_main {
  border: 1px solid #000000;
  border-radius: 8px;
}

.service_images01 {
  width: 55px;
  height: 55px;
}

.icon_title01 {
  font-size: 14px;
}

.min_hours01 {
  font-size: 18px;
}

.section_sub_main03 {
  background-color: white;
  padding: 10px 10px;
  min-height: 450px;
  border-radius: 8px;
  border: 1px solid #9586a4;
}

.hours_detail01 {
  color: #a080e1;
  font-size: 22px;
  padding-top: 30px;
}

.cleaning_ptoducts_need {
  color: #b1a6bc;
  padding: 30px 0px;
  font-size: 12px;
}

.detail_box {
  width: 70%;
}

.anything02 {
  font-size: 13px;
  font-weight: 100;
}

.udrl01 {
  color: #a080e1;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300;
}

.tasks_heading01 {
  font-size: 18px;
}

.continue_to_payment01 {
  border: none;
  background-color: #a080e1;
  color: white;
  text-decoration: none;
  padding: 4px 15px;
  border-radius: 8px;
  font-size: 13px;
}

@media screen and (max-width: 1180px) {
  .anything02 {
    font-size: 11px;
  }
  .time_booking {
    font-size: 11px;
  }
}
@media screen and (max-width: 991px) {
  .anything02 {
    font-size: 13px;
  }

  .time_booking {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .section_sub_main03 {
    margin-top: 30px;
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .anything02 {
    font-size: 9px;
  }
}

@media screen and (max-width: 425px) {
  .card_title01 {
    font-size: 22px;
  }

  .for_testing {
    justify-content: center !important;
  }

  .price_tag01 {
    font-size: 24px;
  }
  .service_images01 {
    width: 40px;
    height: 40px;
  }

  .hours_display_responsive {
    justify-content: center;
    align-items: center;
  }

  .cleaning_ptoducts_need {
    font-size: 12px;
  }

  .location_icon {
    width: 15px;
    height: 20px;
  }

  .location_icon_1,
  .location_icon_2 {
    width: 15px;
    height: 15px;
  }

  .time_booking {
    font-size: 11px;
  }

  .date_time_display {
    padding-bottom: 30px;
  }

  .hours_display_responsive {
    justify-content: center !important;
    padding-top: 20px;
  }

  .hours_detail01 {
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 320px) {
  .time_booking {
    font-size: 10px;
  }
}
