.your_services_main_summary_box {
  border-radius: 8px;
  padding: 0px 30px;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 85vh;
  scrollbar-color: transparent transparent;
}


.your_services_main_summary_box::-webkit-scrollbar {
  width: 0px;
}

.your_services_main_summary_box::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.your_services_main_summary_box::-webkit-scrollbar-track {
  background-color: transparent;
}

.main_booking_success {
  width: 100%;
}

.btn_unpaid_booking_success{
            text-decoration: none;
            color: white;
            font-size: 12px;
            background-color: #a080e1;
            border-radius: 4px;
            padding: 3px 10px;
            margin-right: 20px;

}
.box_main_booking_success {
  background-color: #f6f9f8;
  min-height: 98vh;
  border-radius: 12px;
}

.booking_detail {
  background-color: #ffffff;
}

.your_services_main_summary_box {
  width: 100% !important;
  border-radius: 10px;
  padding: 0px 30px 30px 30px !important;
}

.success_heading {
  color: #a080e1;
  font-weight: 500;
}

.profile_img_success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #a080e1;
  /* background-size: 100% 100%; */
}
.summary_box_booking_success{
  border: 1px solid #837195;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 20px 20px 20px !important;
}

.location_icon_1 {
  width: 20px;
  height: 20px;
}

.cleaner_name {
  font-size: 24px;
  font-weight: 600;
}

.cleaning_title {
  font-size: 20px;
  color: #a080e1;
}

.service_icons {
  width: 40px;
  height: 40px;
}

.service_icons_title {
  font-size: 13px;
}

.location_detail04 {
  color: #837195;
}

.location_icon {
  width: 18px;
  height: 24px;
}
.img_price_booking_success{
  font-size: 26px;
  color: #a080e1;
  font-weight: 600;
}

.icon_box_1 {
  padding: 0px 10px 0px;
}

.icon_box_2 {
  padding-right: 10px;
}

.time {
  font-size: 20px;
  color: #a080e1;
}

.product_not_needed_booking_success {
  font-size: 12px;
  color: #837195;
}

.not_needed {
  margin-top: 80px;
}

.cleaner_name_service {
  display: flex;
  justify-content: flex-start;
}

.img_box {
  display: flex;
  justify-content: center;
}

.cancel_button {
  border: none;
  background-color: transparent;
  color: #a080e1;
}

.continue_to_payment_booking_success {
  border: none;
  background-color: #a080e1;
  color: white;
  padding: 3px 24px;
  border-radius: 14px;
  font-size: 13px;
}

.arrow {
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 1024px) {
  .summary_box_booking_success {
    width: 100% !important;
  }
  .product_not_needed_booking_success {
    font-size: 10px;
  }

  .location_detail04 {
    font-size: 12px;
  }
  .working_hours {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  .cleaner_name {
    font-size: 20px;
  }

  .cleaning_title {
    font-size: 14px;
  }

  .profile_img_success {
    width: 50px;
    height: 50px;
  }

  .price_tag {
    height: 30px;
    width: 40px;
  }

 

  .service_icons_main {
    justify-content: space-around;
  }

  .location_calendar_box {
    margin-top: 20px;
  }

  .not_needed {
    margin-top: 20px;
  }
}

.blue {
  background-color: #a080e1;
}

@media screen and (max-width: 768px) {
  .your_services_main_summary_box {
    padding-top: 10px !important;
    margin-top: 20px;
    width: 70%;
  }
  .text_ttile{
    text-align: center;
  }
  .cleaner_name {
    margin-top: 20px;
  }

  .cleaner_name_service {
    justify-content: center;
  }

  .icon_box_1 {
    padding: 0px 00px 0px;
  }

  .location_detail04 {
    font-size: 12px;
  }
  .profile_img_success {
    width: 80px;
    height: 80px;
  }

  .icon_box_2 {
    padding-right: 0px;
  }

  .main_booking_success {
    width: 90%;
  }

  .summary_box_booking_success {
    width: 100% !important;
  }

  .working_hours {
    margin-top: 20px;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main_summary_box {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }

  .img_price {
    padding-top: 40px;
  }
}

@media screen and (max-width: 425px) {
  .location_detail04 {
    font-size: 12px;
  }

  .your_services_main_summary_box {
    padding: 0px !important;
  }

  .img_price {
    padding-top: 10px;
  }

  .summary_box_booking_success {
    padding: 30px 0px 30px 0px !important;
  }
  .details_locations_bsuccess{
    padding: 0px 30px;
  }
  .continue_to_payment_booking_success {
    padding: 3px 24px;
    font-size: 11px;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main_summary_box {
    padding: 30px;
    width: 90%;
  }

  .location_detail04 {
    font-size: 10px;
  }

  .your_services_main_summary_box {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }
}
