.enter_address {
  font-weight: 700;
  margin: 30px 0px 20px 30px;
}

.your_service_main {
  margin-bottom: 90px !important;
  padding: 0px 30px !important;
}

.Icon_location {
  width: 15px;
  height: 23px;
}
.cleaning-products-extra {
  color: #a080e1;
}

.location {
  color: #837195;
}

.your_services_main {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  height: 375px;
}

.additional_2 {
  margin-top: 98px !important;
}

.login_btn_next {
  background-color: #a080e1;
  border: none;
  color: white;
  border-radius: 15px;
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 90px;
}

.login_btn_next_1 {
  display: flex;
  justify-content: flex-end;
}

input[type="radio"] {
  display: none;
}
.options_yon {
  color: black;
}

input[type="radio"] + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
}

input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 6px;
  width: 12px;
  height: 12px;
  border: 2px solid #d2ebb5;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
}

.additional_charges {
  height: 20px;
  width: 90px;
}

input[type="radio"]:checked + label:before {
  background-color: #d2ebb5;
}

.radio {
  border: 1px solid #808080;
  border-radius: 8px;
  width: 200px;
  margin: 5px;
}

.radio input[type="radio"] {
  color: #a080e1;
}

.icon_cleaner {
  width: 25px;
  height: 30px;
}

input[type="radio"]::after {
  background-color: #a080e1;
}

@media screen and (max-width: 992px) {
  .login_btn_next_1 {
    margin-top: 30px !important;
  }

  .login_btn_next {
    margin-top: 0px;
  }

  .enter_address {
    font-weight: 700;
    margin: 30px 0px 20px 20px;
  }
}

@media screen and (max-width: 767px) {
  .your_services_main {
    padding: 1rem;
    width: 70%;
  }

  .section_select {
    margin-top: 40px !important;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }

  .location {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main {
    padding: 30px;
    width: 90%;
  }
  .cleaning-products-card__center {
    display: flex;
    justify-content: center;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .login_btn_next {
    margin-top: 0px;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
}
