.display_profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #a080e1;
}

.btn_select_customer {
  background-color: #a080e1;
  color: white;
  border: none;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.price_tag {
  color: #a080e1;
  font-size: 18px;
}

.card_title {
  padding: 0 !important;
  margin: 0 !important;
}

.card_main {
  border: 1px solid #000000;
  border-radius: 8px;
}

.service_images {
  width: 35px;
  height: 35px;
}

.icon_title {
  font-size: 11px;
}

.min_hours {
  font-size: 12px;
}

.hours_detail {
  color: #a080e1;
}

.anything01 {
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
}

.udrl01 {
  color: #a080e1;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300;
}

.tasks_heading {
  font-size: 14px;
}

@media screen and (max-width: 1170px) {
  .display_profile {
    width: 45px;
    height: 45px;
  }

  .card_title {
    font-size: 14px;
  }

  .min_hours {
    font-size: 9px;
  }

  .start_end_time_cleaner {
    font-size: 12px;
  }

  .price_tag {
    font-size: 13px;
  }

  .btn_select_customer {
    font-size: 10px;
    padding: 2px 10px;
    text-decoration: none;
  }
}

@media screen and (max-width: 1102px) {
  .min_hours {
    font-size: 8px;
  }

  .hours_detail {
    font-size: 12px;
  }

  .start_end_time_cleaner {
    font-size: 10px;
  }
}

@media screen and (max-width: 991px) {
  .display_profile {
    width: 70px;
    height: 70px;
  }

  .card_title {
    font-size: 24px;
  }

  .price_tag {
    font-size: 20px;
  }

  .btn_select_customer {
    font-size: 12px;
    padding: 2px 14px;
  }

  .card_main {
    width: 70%;
  }

  .start_end_time_cleaner {
    font-size: 16px;
  }

  .min_hours {
    font-size: 12px;
  }
}

@media screen and (max-width: 490px) {
  .display_profile {
    width: 45px;
    height: 45px;
  }

  .card_title {
    font-size: 15px;
  }

  .min_hours {
    font-size: 12px;
  }

  .start_end_time_cleaner {
    font-size: 12px;
  }

  .price_tag {
    font-size: 13px;
  }

  .btn_select_customer {
    font-size: 10px;
    padding: 2px 10px;
    text-decoration: none;
  }
}

@media screen and (max-width: 425px) {
  .min_hours {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  .min_hours {
    font-size: 8px;
  }
  
  .start_end_time_cleaner {
    font-size: 10px;
  }
}
