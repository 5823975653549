.main_container {
  background-color: #f6f9f8;
  border-radius: 10px;
}

.heading_setup {
  font-size: 28px;
}

.sub_main {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  margin-left: 0px;
}

.prf_img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 3px solid #2B43A8;
  padding: 1px;
}

.save_btn {
  border: none;
  background-color: #a080e1;
  color: white;
  padding: 1px 18px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 14px;
}

.profile_heading {
  color: #2B43A8;
  font-size: 13px;
}

.customer_name {
  font-weight: 400;
  font-size: 30px;
}

.input-container {
  position: relative;
}

.icon {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 10px;
}

.input-field {
  padding-left: 40px;
  width: 100%;
  height: 35px !important;
  border: 1px solid #b3afaf;
}

.input-field-1 {
  padding-left: 20px;
  width: 100%;
  height: 35px !important;
  border: 1px solid #b3afaf;
}

.f_row {
  margin-top: 70px !important;
}

.row_phone_nmbr {
  width: 67%;
}

.prf_img_1 {
  width: 20px;
  height: 20px;
}

.prf_img_2 {
  width: 15px;
  height: 20px;
}

.btn_edit {
  text-decoration: none;
  color: #2B43A8;
  font-size: 14px;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_submit_btn_box {
  display: flex !important;
}

.edit_btn_icon {
  margin-left: 15px;
}

.edit_icon {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 1024px) {
  .name_heading {
    font-size: 22px;
  }

  .edit_icon {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 768px) {
  .f_row {
    margin-top: 40px !important;
  }

  .field_post_code {
    display: flex;
  }
}

@media screen and (max-width: 425px) {
  .nmbr_field {
    width: 100%;
  }

  .row_phone_nmbr {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .heading_setup {
    padding-top: 10px;
  }
}
