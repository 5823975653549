* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.card_heading {
  color: #a080e1;
  font-weight: 550;
}

.service_card_main_02 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.service_card_sub_main {
  width: 100% !important;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 1rem;
}

.heading_title {
  color: #a080e1;
  text-align: center;
}

.price_icon_img {
  width: 50px;
  height: 40px;
}

p {
  margin-bottom: 0px !important;
}

.hr {
  border: 1px solid #d2ebb5;
  margin-top: 0;
}

.services_icon {
  width: 45px;
  height: 45px;
}

.btn_select {
  color: white;
  background-color: #a080e1;
  padding: 3px 15px !important;
  border: none;
  margin-top: 2px;
  border-radius: 2px;
  font-size: 15px;
}

.terms_conditions03 {
  font-size: 13px;
  font-weight: 100;
  color: #837195;
}

.icon_title {
  font-size: 12px;
}

.scrollable-row_2 {
  display: flex;
  overflow-x: auto; 
  overflow-y: hidden; 
  white-space: nowrap;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.scrollable-row_2::-webkit-scrollbar {
  display: none;
}

.hours {
  color: #837195;
  font-size: 1.2rem;
  margin-top: 0 !important;
}
.service_price_card{
  font-size: 22px;
  color: #837195;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .btn_select {
    margin-right: 5px;
  }
}

@media screen and (max-width: 375px) {
  .card_heading {
    font-size: 16px;
  }

  .btn_select {
    padding: 2px 13px !important;
    font-size: 14px;
  }

  .minimum_hours_service_card {
    font-size: 14px;
  }

  .services_icon {
    width: 35px;
    height: 35px;
  }
  .terms_conditions03 {
    font-size: 11px;
  }
}
@media screen and (max-width: 320px) {
  .terms_conditions03 {
    font-size: 10px;
  }
}