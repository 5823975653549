.bg_light {
  min-height: 100vh;
}

.logout-link {
  position: absolute;
}

.sidebar-links {
  text-decoration: none;
  color: #000;
  padding: 0.5rem 2rem;
}

.profile_icon_img {
  width: 40px;
  height: 40px;
}

.side_nav_main {
  position: relative;
}

.logout_sidebar_link {
  position: absolute;
  bottom: 20px;
}

.home_icon_img {
  width: 40px;
  height: 35px;
}

.appointment_icon_img {
  width: 40px;
  height: 35px;
}

.setting_icon_img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.logout_icon_img {
  width: 30px;
  height: 30px;
}

.bar_link {
  color: black;
  text-decoration: none;
}

.nav_links_names {
  text-decoration: none;
}

.logout_link {
  margin-top: auto !important;
}

.ml-sm-auto {
  padding: 0 !important;
}

.layout_customer_bg {
  background-image: linear-gradient(to bottom, #2940A6, #93e8ff);

}

.sidebar-active {
  background: #2B43A8;
  color: #b698f1;
}

.img-active {
  filter: drop-shadow(0px 1000px 0 #a080e1);
  transform: translateY(-1000px);
}

@media screen and (max-width: 1220px) {
  .nav_links_names {
    font-size: 12px;
  }
}

@media screen and (max-width: 1080px) {
  .nav_links_names {
    font-size: 11px;
  }
}

@media screen and (max-width: 1024px) {
  .nav_links_names {
    font-size: 15px;
    display: none;
  }

  .profile_icon_img {
    width: 50px;
    height: 50px;
  }

  .home_icon_img {
    width: 50px;
    height: 45px;
  }

  .appointment_icon_img {
    width: 50px;
    height: 45px;
  }

  .setting_icon_img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .logout_icon_img {
    width: 40px;
    height: 40px;
  }

  .side_link_box {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .nav_links_names {
    display: none;
    font-size: 20px;
  }

  .profile_icon_img {
    width: 50px;
    height: 50px;
  }

  .home_icon_img {
    width: 50px;
    height: 45px;
  }

  .appointment_icon_img {
    width: 50px;
    height: 45px;
  }

  .setting_icon_img {
    width: 50px;
    height: 45px;
    margin-right: 5px;
  }

  .logout_icon_img {
    width: 40px;
    height: 40px;
  }

  .side_link_box {
    display: flex;
    justify-content: center;
  }

  .bg_light {
    min-height: auto;
  }

  .side_link_box {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .nav_links_names {
    display: block;
    font-size: 20px;
  }

  .logout_sidebar_link {
    position: static;
  }
}

@media screen and (max-width: 425px) {
  .profile_icon_img {
    width: 40px;
    height: 40px;
  }

  .home_icon_img {
    width: 40px;
    height: 35px;
  }

  .appointment_icon_img {
    width: 40px;
    height: 35px;
  }

  .setting_icon_img {
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }

  .logout_icon_img {
    width: 30px;
    height: 30px;
  }

  .nav_links_names {
    display: block;
  }

  .side_link_box {
    justify-content: flex-start;
  }

  .bg_light {
    min-height: auto;
  }
}
