.udrl{
    color: #A080E1;
    text-decoration: underline;
}
.sm_font03{
    font-size: 10px;
}

.profile_img03 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #a080e1;
    background-size: 100% 100%;
}
.cleaner_name03 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .cleaning_title03 {
    font-size: 20px;
    color: #a080e1;
  }
  .price_tag03 {
    color: #a080e1;
    font-size: 26px;
  }
  .included_tasks_booking_ntf{
    font-size: 16px;
  }
  .location_icon03 {
    width: 12px ;
    height: 18px;
}
.anything03{
    font-size: 10px;
}
.location_icon_3 {
    width: 20px;
    height: 20px;
    margin-left: 20px;
}
@media screen and (max-width: 1200px) {
    .included_tasks_booking_ntf{
        font-size: 12px;
      }
      .anything03{
        font-size: 10px;
    }
    .date_format_ucbooking03{
        font-size: 12px;
    }
    .sm_font03 {
        font-size: 9px;
    }
     
}
@media screen and (max-width: 1024px) {
    .profile_img03 {
        width: 50px;
        height: 50px;
    }
    .cleaner_name03 {
        font-size: 18px;
      }
      .cleaning_title03 {
        font-size: 16px;
      }
      .date_format_ucbooking03{
        font-size: 9px;
    }
    .location_icon_3 {
        margin-left: 0px;
    }
      
     
}
@media screen and (max-width: 991px) {
    .sm_font03{
        font-size: 10px;
    }
    .profile_img03 {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid #a080e1;
        background-size: 100% 100%;
    }
    .cleaner_name03 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .cleaning_title03 {
        font-size: 20px;
        color: #a080e1;
      }
      .price_tag03 {
        color: #a080e1;
        font-size: 26px;
      }
      .included_tasks_booking_ntf{
        font-size: 16px;
      }
      .location_icon03 {
        width: 12px ;
        height: 18px;
    }
    .anything03{
        font-size: 10px;
    }
    .location_icon_3 {
        margin-left: 20px;
    }
}
@media screen and (max-width: 767px) {
    .service_title_uc_booking{
     display: flex;
        justify-content: center;
    }
}
@media screen and (max-width: 425px) {

    .udrl{
        font-size: 8px !important;
    }
   
    .location_detail{
        padding: 0px 22px !important;
    }

}
@media screen and (max-width: 375px) {
    .location_icon_3 {
        margin-left: 0px !important;
    }
    .anything03{
        font-size: 8px !important;
    }
}