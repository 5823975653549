.todays_booking_box {
  background-color: #f6f9f8;
  height: 93vh;
  width: 95%;
  border-radius: 12px;
}

.todays_booking_box1 {
  background-color: #f6f9f8;
  height: 93vh;
  width: 95%;
  border-radius: 12px;
  padding: 0 !important;
}

.btn_activities_seeAll {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.t_b_heading_db {
  font-size: 22px;
}

.t_b_heading_db1 {
  font-size: 18px;
}

.today_booking_main {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.today_booking_main::-webkit-scrollbar {
  width: 0px;
}

.today_booking_main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.today_booking_main::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 425px) {
  .t_b_heading_db {
    font-size: 18px;
  }

  .btn_activities_seeAll {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  .t_b_heading_db1 {
    font-size: 16px;
  }
}
