.cleaner_img_successfull {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.added_title {
  color: #a080e1;
  font-size: 18px;
}

.cleaner_name_head {
  color: #62557f;
}

.wa_icon {
  width: 25px;
  height: 25px;
}

.wa_info_box {
  padding-top: 20px;
}

@media screen and (max-width: 991px) {
  .img_successfull_admin {
    justify-content: center !important;
  }

  .details_cleaners_admin {
    justify-content: center;
  }

  .avalibility_cleaner {
    text-align: center;
  }

  .added_details {
    justify-content: center;
  }

  .added_details {
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .added_title {
    font-size: 14px;
  }
  .media_detail {
    font-size: 14px;
  }
}
