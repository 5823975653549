* {
  font-family: Arial, Helvetica, sans-serif;
}

.services_main {
  min-height:82vh;
  width: 100%;
}

.our_services_heading {
  color: white;
}
