@media screen and (max-width : 570px) {
    
    .customer_name_heading {
        font-size: 20px;
    }

    .title_card{
        font-size: 12px;
    }

    .anything_udrl{
        font-size: 10px;
    }

    .anything01 {
        font-size: 11px !important;
    }

    .location_icon {
        width: 12px !important;
        height: 18px !important;
    }
}

@media screen and (max-width : 425px) {
    .anything01 {
        font-size: 9px !important;
    }

    .location_icon_1 {
        width: 17px !important;
        height: 17px !important;
    }
}

@media screen and (max-width : 350px) {
    .title_card{
        font-size: 9px !important;
    }

    .btm_brd{
        padding: 5px 0px !important;
    }
    .customer_name_heading {
        font-size: 14px !important;
    }
}
