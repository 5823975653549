.your_service_main {
  margin-bottom: 90px !important;
  padding: 0px 30px !important;
}

.your_services_main {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 3rem;
  width: 100%;
  height: 375px;
}

.section_select {
  margin-top: 120px !important;
}

.location_box {
  border-radius: 8px;
  width: 250px;
  height: 240px;
  background: linear-gradient(to right, #e9e9e9 0%, #f5f5f5 50%, #e9e9e9 100%);
}

.allow_access {
  border: none;
  color: #4988e1;
  background-color: transparent;
}

.allow_location_heading {
  font-size: 17px;
}

.location_link {
  text-decoration: none;
  color: black;
  font-size: 13px;
}

.hr_location {
  margin: 0;
  color: rgb(126, 126, 126);
}

.login_btn_next {
  text-decoration: none;
}

.enter_manually {
  background: none;
  border: none;
  text-decoration: none;
  color: #a080e1;
  font-weight: 200;
}

.btn_allow_access_location{
  background-color: transparent;
  border: none;
  color: #a080e1;
}

.allow_location_icon {
  width: 86px;
  height: 146px;
}

.allow_location_icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .your_services_main {
    padding: 1rem !important;
    width: 70%;
  }

  .section_select {
    margin-top: 40px !important;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .allow_location_heading {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main {
    padding: 2rem;
    width: 90%;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
}
