.appoint_cleaner_main {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 93vh;
}

.today_cleaners_main {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
