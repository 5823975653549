* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.main {
  background-color: #f6f9f8 !important;
  width: 100% !important;
  margin-top: 20px;
  padding-top: 0px !important;
  margin-bottom: 20px;
  border-radius: 8px;
  height: auto;
}

.heading_our_services {
  padding: 0px 0px 10px 0px !important;
}

.main_box {
  background-color: white;
  border-radius: 8px;
  overflow-y: auto;
    height: 90vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.main_box::-webkit-scrollbar {
  width: 0px;
}

.main_box::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.main_box::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width : 375px) {
  
  .main_box {
   margin: 0px 15px 15px 15px;
  }
  .heading_our_services {
    padding-top: 10px !important;
  }
}