.booking_upcoming_section_5 {
  background-color: white;
  padding: 10px 10px;
  border-radius: 8px;
  height: 100vh;
  overflow: hidden;
}

.section_sub_main02 {
  background-color: white;
  padding: 10px 10px;
  height: 50vh;
  border-radius: 8px;
}

.customer_booking_hp {
  overflow-y: auto;
  max-height: 88vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.main_cleaner_booking {
  padding: 20px 20px 20px;
}

.section_main_bookins_clenaer {
  padding: 20px 10px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.customer_booking_hp::-webkit-scrollbar {
  width: 0px;
}

.customer_booking_hp::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.customer_booking_hp::-webkit-scrollbar-track {
  background-color: transparent;
}

.customer_past_booking_hp {
  overflow-y: auto;
  max-height: 42vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.customer_past_booking_hp::-webkit-scrollbar {
  width: 0px;
}

.customer_past_booking_hp::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.customer_past_booking_hp::-webkit-scrollbar-track {
  background-color: transparent;
}

@media screen and (max-width: 991px) {
  .section_sub_main02 {
    margin-top: 20px;
  }

  .notification_box_lists_cleaner {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .notification_box_lists_cleaner02 {
    margin-top: 20px !important;
  }
}
