* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.card_heading {
  color: #a080e1;
  font-weight: 550;
  height: 2.5rem;
}

.service_card_main {
  display: flex;
  justify-content: center;
}

.service_card_sub_main07 {
  width: 16rem;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 1rem;
}
.scrollable-row {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.heading_title {
  color: #a080e1;
  text-align: center;
}

.price_icon_img {
  width: 50px;
  height: 40px;
}

p {
  margin-bottom: 0px !important;
}

.hr {
  border: 1px solid #d2ebb5;
  margin-top: 0;
}

.services_icon {
  width: 45px;
  height: 45px;
}

.select_button {
  color: white;
  background-color: #a080e1;
  padding: 4px 24px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  text-decoration: none;
}

.terms_conditions {
  font-size: 13px;
  font-weight: 100;
  color: #837195;
}

.icon_title {
  font-size: 12px;
}

.hours {
  color: #837195;
  font-size: 1.2rem;
  margin-top: 0 !important;
}

.popup-overlay {
  z-index: 122;
}

@media screen and (max-width: 1024px) {
  .select_button {
    padding: 3px 18px;
    font-size: 12px;
  }

  .terms_conditions {
    font-size: 12px;
    font-weight: 100;
  }
  .service_card_sub_main07 {
    width: 14rem;
  }
}
@media screen and (max-width: 992px) {
  .select_button {
    padding: 3px 18px;
    font-size: 12px;
  }

  .terms_conditions {
    font-size: 12px;
    font-weight: 100;
  }
}

@media screen and (max-width: 575px) {
  .box_select_button_main {
    justify-content: center !important;
  }
  .service_card_sub_main07 {
    width: 16rem;
  }
}

@media screen and (max-width: 425px) {
  .select_button {
    color: white;
    background-color: #a080e1;
    padding: 4px 24px;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    text-decoration: none;
  }

  .terms_conditions {
    font-size: 13px;
    font-weight: 100;
    color: #837195;
  }
}

@media screen and (max-width: 425px) {
  .box_service_main_card {
    width: 77% !important ;
  }
}
