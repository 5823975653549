.btn_ucbooking_see {
  background-color: transparent;
  color: #af94e6;
  border: none;
  text-decoration: none;
  font-size: 15px;
}

.lists_heading_cleaner {
  font-size: 18px;
}

.btn_seeall_cleaner_head {
  display: flex;
  align-items: flex-end;
}
