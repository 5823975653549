.notication_admin {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 92vh;
}

.notifications_admin_details {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.notifications_admin_details::-webkit-scrollbar {
  width: 0px;
}

.notifications_admin_details::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.notifications_admin_details::-webkit-scrollbar-track {
  background-color: transparent;
}

.notification_box_details {
  background-color: white;
  border-radius: 8px;
  height: 80vh;
}

.notification_cleaner_card {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 92vh;
  padding: 0px 10px;
}

@media screen and (max-width: 768px) {
  .notification_cleaner_card {
    height: auto;
  }
}
