.nav_search_type {
  background-color: #fefefe;
  border: 1px solid #f5f4f4;
  background-color: #f5f4f4;
  border-radius: 42px;
  width: 70%;
  height: 30px;
}

.logo_image04 {
  width: 55px;
  height: 60px;
}

.nav_search_type::placeholder {
  color: #c8c5cb;
}

.profile_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.notification_icon {
  width: 25px;
  height: 25px;
}
.notification-link {
  position: relative;
}

.notification-count {
  text-decoration: none;
  position: absolute;
  left: 58%;
  top: -30%;
  color: #fff;
  background-color: brown;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .profile_image {
    width: 40px;
    height: 40px;
  }

  .notfication_icon {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 425px) {
  .nav_search_type {
    width: 100%;
  }
}
