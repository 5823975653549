.main_dashboard {
  min-height: 100vh;
}

.activity_main {
  background-color: #f6f9f8;
  margin-top: 20px;
  border-radius: 8px;
  padding-bottom: 20px;
  height: 95vh;
  overflow: hidden;
  white-space: nowrap;
}
.total_booking_today {
  margin-top: 1.3rem;
  background-color: #f6f9f8;
  border-radius: 8px;
  min-height: 44vh;
}
.sort_by_heading {
  color: #9182a2;
}
.sort_by {
  background-color: transparent;
  border: none;
  width: 85px;
}
.chart_bar {
  background-color: #f6f9f8;
  margin-top: 1.3rem;
  border-radius: 8px;
  min-height: 44vh;
}
.see_all_dashboard {
  background-color: transparent;
  border: none;
}
.total_booking_heading {
  font-size: 18px;
  color: #9182a2;
}
.total_bookings {
  font-size: 120px;
  font-weight: bolder;
  color: #a080e1;
  text-shadow: -5px 0 4px #bdf083;
}
.upcoming_booking_dashboard {
  background-color: #f6f9f8;
  border-radius: 8px;
  height: 48.5vh;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0.1rem 1rem 0.1rem;
}

.btn_see_dashboard {
  background-color: transparent;
  border: none;
  font-weight: 500;
}
@media screen and (max-width: 991px) {
  .upcoming_booking_dashboard {
    height: 60vh;
  }
}
@media screen and (max-width: 700px) {
  .upcoming_booking_heading {
    font-size: 22px;
  }
  .upcoming_booking_dashboard {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 425px) {
  .upcoming_booking_heading {
    font-size: 18px;
  }
}
