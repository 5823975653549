.checkbox-container01 {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  color: #837195;
}





.main_upcoming_booking_cleaner_box{
  background-color: #f6f9f8 !important;
  width: 100% !important;
  margin-top: 20px;
  padding-top: 0px;
  margin-bottom: 20px;
  border-radius: 8px;
  height: 100vh;
  padding: 30px 30px !important;
}
.upcoming_booking_cleaner_section_main {
  border-radius: 8px;
  padding: 0px 0px;
}
.upcoming_booking_cleaner_section_sub_main {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  height: 90vh;
}

.upcoming_booking_cleaner_section_sub_main01 {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  height: 90vh;
  position: relative;
}

.upcoming_booking_list_cleaner_main {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.upcoming_booking_list_cleaner_main::-webkit-scrollbar {
  width: 0px;
}

.upcoming_booking_list_cleaner_main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.upcoming_booking_list_cleaner_main::-webkit-scrollbar-track {
  background-color: transparent;
}







.checkbox-container01 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark01 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 4px;
}
.show_time01 {
  color: #a080e1;
  font-size: 14px;
  padding: 2px 4px;
  border: 1px solid #808080;
  border-radius: 4px;

  /* padding: 0 !important; */
}

.checkbox-container01 input:checked + .checkmark01 {
  background-color: #d2ebb5;
  border: none;
}

.checkmark01:after {
  content: "";
  position: absolute;
  display: none;
}
.btm_brd {
  padding: 5px 10px;

  border-radius: 15px;
  cursor: pointer;
}

.bookingCard-selected {
  background-color: #e0dbe4;
}

.cleaner_detail {
  border-bottom: 1px solid #e3e3e3 !important;
}
.customer_name_heading {
  padding: 0;
  margin: 0;
  font-size: 24px;
}
.anything01 {
  color: #837195;
}
.loc_detail {
  margin-left: 10px;
}

.checkbox-container01 input:checked + .checkmark01:after {
  display: block;
}

.checkbox-container01 .checkmark01:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.btn_box_01 {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .main_upcoming_booking_cleaner_box{
    height: auto;
  }
  .upcoming_booking_cleaner_section_sub_main01{
    height: auto;
    padding-bottom: 80px;
    margin-top: 20px;
  }
 
}

@media screen and (max-width: 425px) {
  .customer_name_heading {
    font-size: 16px;
  }

  .title_card {
    font-size: 12px;
  }
  .anything {
    font-size: 10px;
  }
  .anything01 {
    font-size: 11px !important;
  }

  .toogle_time {
    margin: 0px 10px !important;
  }
  .checkmark01 {
    height: 15px;
    width: 15px;
  }
  .checkbox-container01 {
    padding-left: 23px;
    margin-bottom: 11px;
    font-size: 11px;
    width: 140px;
    color: #837195;
}
.show_time01 {
  font-size: 10px;
}
.checkbox-container01 .checkmark01:after {
  left: 5px;
  top: 1px;
}
.arrived_location_checkbox{
  width: 60%;
}
.btn_ntf_confirm{
  font-size: 12px !important;
}
}
@media screen and (max-width: 335px) {
  .padding_style{
    padding: 0px !important;
  }
  
}

