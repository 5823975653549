.activity_heading {
  font-size: 20px;
  font-weight: 600;
}

.db_cleaner_card {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 10px 0px;
  border-radius: 20px;
}

.db_display_img {
  width: 50px;
  height: 50px;
}

.display_time_dashboard {
  font-size: 18px;
  color: #a080e1;
  padding-top: 5px;
}

.db_cleaner_heading {
  margin: 0;
  font-size: 17px;
  padding: 0;
  color: #a080e1;
}

.db_service {
  font-size: 14px;
  color: #a080e1;
}

.img_box {
  padding: 0;
}

.btn_site {
  border: none;
  color: white;
  border-radius: 10px;
  padding: 0px 5px;
  background-color: #d2ebb5;
  font-size: 12px;
}

.menu_btn_dashboard {
  background-color: transparent;
  border: none;
}

.img_menu {
  width: 25px;
  height: 6px;
}

.location_dashboard {
  font-size: 9px;
  padding: 0;
  color: #a080e1;
}

@media screen and (max-width: 375px) {
  .location_dashboard {
    font-size: 7px;
  }
}
