.admin_appoint_details {
  background-color: #f8f9fa;
  border-radius: 8px;
  min-height: 93vh;
}

.appointing_cleaner {
  color: #a080e1;
  font-size: 15px;
}
