.add_cleaners_main_admin {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 94vh;
}

.btn_add_cleaner {
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #a080e1;
  text-decoration: none;
}

#select_cleaners_all {
  background-color: white;
  padding: 2px 0px;
  background-color: white;
  border: none;
  font-size: 12px;
}

.today_cleaners_main {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
