.appoint_this_cleaner {
  font-size: 12px;
  color: #a080e1;
  background-color: transparent;
  border: none;
}

.unavailable_cleaner {
  padding: 0;
  margin: 0;
  font-size: 12px !important;
  font-weight: 300 !important;
}
