.remove_email {
  border-radius: 5px;
  width: 300px;
}

.change_email {
  color: #a080e1;
  font-weight: 200;
  font-size: 12px;
}

.btn_confirm {
  color: white;
  background-color: #a080e1;
  border: none;
  padding: 5px 10px 3px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 200;
}
.password_number_settings{
  font-size: small;
}

.remove_heading {
  margin-left: 30px;
}

.btn_confirm_div {
  margin-top: 120px;
}

@media screen and (max-width: 992px) {
  .remove_email {
    width: 250px !important;
  }

  .remove_heading {
    margin-left: 5px !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .remove_email {
    width: 250px !important;
  }
}

@media screen and (max-width: 767px) {
  .remove_email {
    width: 400px !important;
  }
}

@media screen and (max-width: 560px) {
  .remove_email {
    width: 300px !important;
  }
}

@media screen and (max-width: 425px) {
  .remove_email {
    width: 250px !important;
  }

  .input_lables_settings {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  .remove_email {
    width: 200px !important;
  }
  
  .remove_heading {
    font-size: 14px;
  }
}
