.main_sideBar_dashboard {
  padding: 0;
}

.outlet_style {
  background-color: #d2ebb5;
  padding: 0;
}

.error_box_main {
  min-height: 90vh;
  background-image: linear-gradient(to right, #ffffff, #d2ebb5);
}

.oops {
  font-size: 5rem;
}
.logout-btn {
  margin-top: 2rem;
  background-color: #a080e1;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 0.3rem 0.5rem;
}

@media screen and (max-width: 850px) {
  .oops {
    font-size: 6rem;
  }

  .error_content {
    font-size: 14px;
  }

  .main_content_error {
    padding-left: 30px;
  }
}

@media screen and (max-width: 500px) {
  .oops {
    font-size: 4rem;
  }

  .error_content {
    font-size: 14px;
  }
}
