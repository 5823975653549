.popup-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  height: 30rem;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.scroll-popup {
  width: 28rem;
  height: 28rem;
  overflow: auto;
}

.scroll-popup::-webkit-scrollbar {
  width: 8px;
}

.scroll-popup::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.scroll-popup::-webkit-scrollbar-track {
  background-color: #eee;
}

.scroll-popup * {
  scrollbar-width: thin;
  scrollbar-color: #888 #eee;
}

.popup-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}

.btn_accept_and_continue {
  background-color: #a080e1;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 2px 10px;
}

.wider_popup {
  width: 900px;
}

.terms_heading {
  text-align: center;
}
.cross_button{
  color: white;
  background-color: #a080e1;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  width: 26px;
    height: 24px;
}

@media screen and (max-width: 1024px) {
  .popup-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24rem;
    height: 24rem;
  }
  .scroll-popup {
    width: 24rem;
    height: 24rem;
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .popup-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15rem;
    height: 15rem;
    font-size: 11px;
  }
  .scroll-popup {
    width: 15rem;
    height: 15rem;
    overflow: auto;
  }

  .terms_checkbox_required{
    font-size: 12px;
  }

  .terms_heading {
  font-size: 19px;
  }
.cross_button{

  padding: 0px 6px;
    width: 23px;
    height: 20px;
}
}