.prof_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #a080e1;
}

.customer_name {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.title_service_admin {
  color: #a080e1;
}

.price_admin {
  font-size: 25px;
  color: #a080e1;
}

.img_loc {
  width: 15px;
  height: 19px;
}

.loction_details {
  font-size: 14px;
  color: #7559af;
}

.img_clock {
  width: 15px;
  height: 15px;
}

.cleaning_products {
  font-size: 12px;
  color: #7559af;
}

.button_box {
  margin: 1.2rem;
}

.btn_cancel_cleaner {
  background-color: white;
  padding: 4px 20px;
  border-radius: 6px;
  border: 1px solid;
  font-size: 14px;
}

.btn_appoint_cleaner {
  background-color: transparent;
  font-size: 14px;
  color: #a080e1;
  border: none;
}

@media screen and (max-width: 767px) {
  .img_box_customer_detail {
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .loction_details {
    font-size: 12px;
  }

  .cleaning_products {
    font-size: 10px;
  }

  .customer_detail_admin {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 340px) {
  .loction_details {
    font-size: 10px;
  }
}
