.btn_see_notification {
  background-color: transparent;
  color: #af94e6;
  border: none;
  text-decoration: none;
}

.services_time_location {
  background-color: #f3f1f5;
  padding: 3px 10px;
  border-radius: 9px;
  cursor: pointer;
}

.notification_parg_unread {
  color: #000;
}

.notification_parg_read {
  color: #949494;
}

.notification-active {
  background-color: #d1d1d1;
  color: #000 !important;
}

.customer_notification_hp {
  overflow-y: auto;
  /* max-height: 42vh; */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.customer_notification_hp::-webkit-scrollbar {
  width: 0px;
}

.customer_notification_hp::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.customer_notification_hp::-webkit-scrollbar-track {
  background-color: transparent;
}

.notification_bookings_list_head {
  font-size: 18px;
}

.btn_see_notification {
  font-size: 15px;
}

.btn_view_cleaner {
  border: none;
  background-color: transparent;
  color: #af94e6;
  font-size: 12px;
}

@media screen and (max-width: 550px) {
  .notifiation_cleaner_parg {
    font-size: 13px;
  }
}

@media screen and (max-width: 374px) {
  .notifiation_cleaner_parg {
    font-size: 11px;
  }

  .btn_view_cleaner {
    font-size: 11px;
  }
}
