.prof_img {
  width: 70px;
  height: 70px;
}

.customer_name {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.button_box {
  margin-top: 120px;
}
.approve-cleaner__buttons {
  padding: 2rem;
  margin-top: 5rem;
}

.btn_approve_cleaner_account,
.btn_reject_cleaner_account {
  background-color: white;
  padding: 4px 20px;
  border-radius: 6px;
  border: 1px solid;
  font-size: 14px;
}

.btn_reject_cleaner_account {
  background-color: #a080e1;
  color: white;
  border: none;
  font-size: 14px;
}

.button_box2 :hover {
  background-color: #8369b9;
}

.button_box :hover {
  background-color: #f8f9fa;
}

@media screen and (max-width: 768px) {
  .img_box_customer_detail {
    justify-content: center;
  }
  .approve-cleaner__buttons {
    margin-top: 0rem !important;
  }
}

@media screen and (max-width: 425px) {
  .loction_details {
    font-size: 12px;
  }
  .approve-cleaner__buttons {
    margin-top: 0rem !important;
  }

  .cleaning_products {
    font-size: 10px;
  }

  .customer_detail_admin {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 340px) {
  .loction_details {
    font-size: 10px;
  }
}
