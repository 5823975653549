.arrow {
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
}

.btn_img {
  width: 40px;
  height: 40px;
}

.display_month {
  color: #a080e1;
  width: 200px;
}

.btn_week_arrows {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.display_days {
  width: 64px;
  display: flex;
  justify-content: center;
}

.week_days {
  color: #a080e1;
  padding: 0px 40px;
}

.month_names {
  color: #a080e1;
}

.btn_arrow {
  border: none;
  background-color: transparent;
}

.wto_circle {
  width: 15px;
  height: 20px;
}

.btn_calendar_days {
  border: none;
  background-color: transparent;
}
.days_box_calendar {
  width: 40px;
}

.newClassOnButtonClick {
  background-color: #a080e1;
}

.weeks_days_calendar {
  width: 10%;
}

@media screen and (max-width: 1024px) {
  .week_days {
    font-size: 14px;
  }

  .days_arrow {
    width: 25px;
    height: 25px;
  }

  .display_days {
    width: 60px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .btn_week_arrows {
    width: 30px;
    height: 30px;
  }

  .display_days {
    width: 57px;
    font-size: 12px;
  }

  .week_days {
    font-size: 12px;
    padding: 0px 30px;
  }

  .btn_img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 580px) {
  .week_days {
    font-size: 10px;
    padding: 0px 5px;
  }

  .weekday {
    padding: 0px 10px !important;
  }

  .display_days {
    width: 37px;
    font-size: 11px;
  }

  .btn_week_arrows {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 425px) {
  .weekday {
    padding: 0px 5px !important;
  }

  .btn_img {
    width: 25px;
    height: 25px;
  }

  .display_month {
    width: 170px;
    font-size: 13px;
  }

  .display_days {
    width: 26px;
    font-size: 11px;
  }
}

@media screen and (max-width: 350px) {
  .weekday {
    padding: 0px 3px !important;
  }

  .display_days {
    width: 23px;
    font-size: 11px;
  }
}
