.remove_email {
  border-radius: 5px;
  width: 300px;
}

.change_email {
  color: #a080e1;
  font-weight: 200;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.btn_confirm_email {
  color: white;
  background-color: #a080e1;
  border: none;
  padding: 5px 14px 3px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 200;
}

.remove_heading {
  margin-left: 30px;
}

.btn_confirm_div {
  margin-top: 120px;
}

.remove_email::placeholder {
  color: #999;
  font-size: 14px;
}
