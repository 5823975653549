.your_service_main {
  margin-bottom: 90px !important;
  padding: 0px 30px !important;
}

.your_services_main {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  height: auto;
}

.section_select {
  margin-top: 30px !important;
}

.btn_book {
  color: white;
  background-color: #a080e1;
  border: none;
  border-radius: 12px;
  padding: 3px 18px;
  font-size: 14px;
}

.first_hr {
  border: 1px solid #000000 !important;
}

.first_hr {
  padding: 0 !important;
  margin-top: 3px !important;
}

.available_cleaner_detail_box {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  padding: 5px 15px !important;
}

.display_img {
  border: 2px solid #a080e1;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.display_img_box {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 10px;
}

.price_tag {
  color: #a080e1;
  font-size: 24px;
}

.btn_select {
  background-color: #a080e1;
  color: white;
  border: none;
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 5px;
}

.avail_cleaner {
  color: #837195;
  font-size: 14px;
  margin-left: 5px;
}

.display_time {
  color: #a080e1;
  background-color: transparent;
  border: 1px solid #d2ebb5;
  border-radius: 5px;
  padding: 2px 5px;
  width: 90px;
}
.display_time_selected {
  color: #a080e1;
  background-color: #d2ebb5;
  border: 1px solid #a080e1;
  border-radius: 5px;
  padding: 2px 5px;
  width: 90px;
}
.display_time_disabled {
  color: #232023;
  background-color: #f5f5f5;
  border: 1px solid #232023;
  border-radius: 5px;
  padding: 2px 5px;
  width: 90px;
}
.display_time_disabled,
.display_time,
.display_time_selected {
  margin-top: 10px;
}

.main {
  background-color: white;
}

.scrollable-row {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.col-5 {
  flex: 0 0 auto;
  width: 35%;
  padding: 0 15px;
  box-sizing: border-box;
}
@media screen and (max-width: 1199px) {
  .display_img_box {
    justify-content: center;
  }
  .ac_cleaner_name_card {
    display: flex;
    justify-content: center;
  }
  .ac_cleaner_price_card {
    margin-top: 0 px !important;
  }
}
@media screen and (max-width: 1024px) {
  .col-5 {
    flex: 0 0 auto;
    width: 60% !important;
    padding: 0 15px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .display_time_disabled,
  .display_time,
  .display_time_selected {
    margin-top: 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .display_time_disabled,
  .display_time,
  .display_time_selected {
    width: 60px;
  }
}

@media screen and (max-width: 600px) {
  .display_time_disabled,
  .display_time,
  .display_time_selected {
    margin-top: 8px;
    margin-right: 3px;
    font-size: 11px;
    width: 60px;
  }
  .col-5 {
    width: 75% !important;
  }
}
@media screen and (max-width: 400px) {
  .display_time_disabled,
  .display_time,
  .display_time_selected {
    font-size: 7px;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main {
    padding: 30px;
    width: 90%;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
}

.available_cleaner_detail_box {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.row {
  display: inline-flex;
}
