.activity_heading1 {
  font-size: 20px;
  font-weight: 600;
}

.db_cleaner_card1 {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 10px 0px;
  border-radius: 20px;
}

.db_display_img1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #a080e1;
}

.imgaes_location_calendar {
  width: 12px;
  height: 12px;
}

.checkbox_container {
  display: flex;
  margin-top: 9px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  width: 150px;
  color: #837195;
}

.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.time_box_db {
  margin-left: 30px;
}

.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #d2ebb5;
  border: none;
  border-radius: 4px;
}

.show_time1 {
  color: #a080e1;
  font-size: 12px;
  padding: 0px 0px;
  border: 1px solid #808080;
  border-radius: 4px;
}

.checkbox_container input:checked + .checkmark1 {
  background-color: #d2ebb5;
  border: block;
}

.checkmark1:after {
  content: "";
  position: absolute;
  display: block;
}

.checkbox_container input:checked + .checkmark1:after {
  display: block;
}

.checkbox_container .checkmark1:after {
  left: 7px;
  top: 3px;
  width: 3px;
  height: 8px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.arrived_location {
  font-size: 12px;
}

.prfCleaner {
  width: 22px;
  height: 25px;
}

.display_time_dashboard1 {
  font-size: 18px;
  color: #a080e1;
  padding-top: 5px;
}

.db_cleaner_heading1 {
  margin: 0;
  font-size: 17px;
  padding: 0;
  color: #a080e1;
}

.db_service1 {
  font-size: 14px;
  color: #a080e1;
}

.db_service2 {
  font-size: 14px;
  color: #d2ebb5;
}

.db_service3 {
  font-size: 14px;
}

.img_box1 {
  padding: 0;
}

.btn_site1 {
  color: black !important;
  border-radius: 3px !important;
  padding: 2px 7px !important;
  background-color: #d2ebb5;
  font-size: 12px;
  border: none;
}

.menu_btn_dashboard1 {
  background-color: transparent;
  border: none;
}

.img_menu1 {
  width: 25px;
  height: 6px;
}

.location_dashboard1 {
  font-size: 9px;
  padding: 0;
  color: #a080e1;
}

.text_done {
  color: #d2ebb5;
}

.toggle-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.toggle-switch {
  background-color: rgb(238, 238, 238);
  width: 38px;
  height: 13px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}

.toggle-switch::before {
  content: "";
  font-weight: 900;
  background-color: gray;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  top: -1px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  transition: 0.3s;
}

.toggle-checkbox:checked + .toggle-switch::before {
  content: "";
  left: 25px;
}

@media screen and (max-width: 1131px) {
  .main_check {
    display: block !important;
  }

  .date_db {
    display: block;
  }

  .time_box_db {
    margin-left: 0px;
  }

  .time_finished_style {
    justify-content: start !important;
  }
}

@media screen and (max-width: 991px) {
  .adjustment {
    justify-content: center !important;
  }

  .available_cleaner {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .today-booking__timestamp {
    justify-content: center !important;
  }
  .db_cleaner_heading1 {
    display: flex;
    justify-content: center;
  }
  .db_service1 {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .today-booking__timestamp {
    justify-content: center !important;
  }
  .location_dashboard1 {
    font-size: 7px;
  }
}

@media screen and (max-width: 375px) {
  .location_dashboard1 {
    font-size: 7px;
  }
}
