.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

.custom-checkbox .checkbox-square {
  position: absolute;
  top: 11px;
  left: 11px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 2px solid #d2ebb5;
  border-radius: 50%;
}

.custom-checkbox input:checked + .checkbox-square {
  background-color: #d2ebb5;
}

.custom-checkbox .checkbox-label-main {
  margin-left: 40px;
  color: #000;
  background-color: transparent;
  width: 100%;
}

.custom-checkbox input:checked + .checkbox-square + .checkbox-label-main {
  background-color: #d2ebb5;
}

.appoint_cleaner {
  background-color: transparent;
  border: none;
  color: #a080e1;
}

.notf_box {
  border: 1px solid black;
  border-radius: 4px;
  padding: 3px 10px;
  width: 100%;
}

.notf_cancel {
  padding-right: 40px;
}

@media screen and (max-width: 600px) {
  .custom-checkbox {
    padding-left: 20px;
  }

  .notf_cancel {
    font-size: 12px;
  }

  .appoint_cleaner {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .custom-checkbox {
    padding-left: 0px;
  }

  .notf_cancel {
    font-size: 11px;
    padding-right: 20px;
  }

  .appoint_cleaner {
    font-size: 11px;
  }
}

@media screen and (max-width: 450px) {
  .notf_box {
    display: block !important;
  }

  .custom-checkbox .checkbox-square {
    top: 20px;
  }
}

@media screen and (max-width: 330px) {
  .notf_cancel {
    padding-right: 0px !important;
  }
}
