.enter_address {
  font-weight: 700;
}

.your_service_main {
  margin-bottom: 90px !important;
  padding: 0px 30px !important;
}

.your_services_main03 {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  min-height: 60vh;
}
.main_box_address {
  margin-top: 120px;
}

.address_input {
  background-color: #fefefe;
  border: 1px solid #808080;
  border-radius: 3px;
  height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.address_input::placeholder {
  color: #c8c5cb;
}

.login_btn_next {
  background-color: #a080e1;
  border: none;
  color: white;
  border-radius: 15px;
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 90px;
}

.address_input_1 {
  width: 90%;
  margin-right: 20px;
}

.address_input_2 {
  width: 70%;
}

.your_service_main {
  display: flex;
  flex-direction: column;
  min-height: 72vh;
}

.your_services_main03 {
  flex: 1;
}

.footer_bottom {
  margin-top: auto;
}

@media screen and (max-width: 1199px) {
  .main_box_address {
    margin-top: 40px;
  }
}

@media screen and (max-width: 992px) {
  .address_input_2 {
    width: 50%;
  }

  .login_btn_next {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .your_services_main03 {
    padding: 30px;
    margin-top: 40px;
    width: 100%;
  }

  .address_input_1 {
    margin-right: 15px;
  }

  .address_input_2 {
    width: 40%;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .login_btn_next {
    margin-top: 60px;
  }

  .your_services_main03 {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }

  .login_btn_next_1 {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 375px) {
  .your_services_main03 {
    padding: 30px;
    margin-top: 20px;
    width: 90%;
  }

  .address_input_2 {
    width: 70%;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main03 {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }

  .address_input_1 {
    width: 100% !important;
  }
}
