.your_services_main_booking_summary {
  background-color: #f6f9f8;
  border-radius: 8px;
  width: 100%;
  height: 95vh;
  background-color: #F8F9FA;
}

.main_summary_box {
  width: 100%;
  height: auto;
}

.booking_detail {
  background-color: #ffffff;
}

.price_style {
  font-size: 20px;
  font-weight: 700;
  color: #a080e1;
}
.heading_booking_summary{
  font-size: 26px;
}

.summary_box01 {
  border: 1px solid #837195;
  background-color: #ffffff;
  width: 80% !important;
  border-radius: 10px;
  padding: 20px 20px 20px 20px !important;
}
.booking_price_summary{
  font-size: 26px;
}
.profile_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #a080e1;
  background-size: 100% 100%;
}

.location_icon_1 {
  width: 20px;
  height: 20px;
}

.cleaner_name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.cleaning_title {
  font-size: 20px;
  color: #a080e1;
}

.service_icons {
  width: 40px;
  height: 40px;
}

.service_icons_title {
  font-size: 13px;
}

.location_detail {
  color: #837195;
}

.location_icon {
  width: 18px;
  height: 24px;
}
.location_summary{
  font-size: 14px;
}
.date_time_format {
  font-size: 14px;
}

.icon_box_1 {
  padding: 0px 10px 0px;
}

.icon_box_2 {
  padding-right: 10px;
}

.time {
  font-size: 20px;
  color: #a080e1;
}

.product_not_needed_booking_summary {
  font-size: 11px;
  color: #a080e1;
}


.weelky_day_names {
  background-color: #d3c5f1;
  width: 150px;
  border-radius: 15px;
  padding: 5px 0px;
}
.weekly {
  color: #7559af;
  font-size: 14px;
}
.cleaner_name_service {
  display: flex;
  justify-content: flex-start;
}

.img_box {
  display: flex;
  justify-content: center;
}

.cancel_button {
  border: none;
  background-color: transparent;
  color: #a080e1;
}

.continue_to_payment {
  border: none;
  background-color: #a080e1;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  font-size: 13px;
  margin-bottom: 30px;
}
.btn_payment_cancel_summary{
  margin-top: 40px;
}

.arrow {
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 1024px) {
  .summary_box {
    width: 90% !important;
  }
  .btn_payment_cancel_summary{
    margin-top: 10px;
  }
}

@media screen and (max-width: 992px) {
  .cleaner_name {
    font-size: 20px;
  }

  .cleaning_title {
    font-size: 14px;
  }

  .profile_img {
    width: 50px;
    height: 50px;
  }

  .price_tag {
    height: 30px;
    width: 40px;
  }

  .summary_box {
    width: 90% !important;
  }

  .service_icons_main {
    justify-content: space-around;
  }

  .location_calendar_box {
    margin-top: 20px;
  }

  .location_detail {
    font-size: 14px;
  }

  .not_needed {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .not_needed {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  

  .cleaner_name {
    margin-top: 20px;
  }

  .cleaner_name_service {
    justify-content: center;
  }

  .icon_box_1 {
    padding: 0px;
  }

  .location_detail {
    font-size: 16px;
  }

  .icon_box_2 {
    padding-right: 0px;
  }

  .your_services_main_booking_summary {
    height: auto;
  }

  .summary_box {
    width: 85% !important;
  }

  .working_hours {
    margin-top: 20px;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }
  .date_time_format {
    font-size: 13px;
  }
  .location_icon_1 {
    width: 20px;
    height: 20px;
  }

  
}

@media screen and (max-width: 767px) {
  .cleaner_name_booking {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 425px) {
  .booking_price_summary{
    font-size: 18px;
  }
  .location_summary{
    font-size: 12px;
  }
  .location_detail {
    font-size: 12px;
  }
  .summary_box01 {
    width: 95% !important;
    padding: 20px 20px 20px 20px !important;
  }
  .your_services_main_booking_summary {
    padding: 0px;
  }
  .heading_booking_summary{
    font-size: 20px;
  }
  .summary_box {
    padding: 30px 0px 30px 0px !important;
  }
  .date_time_format {
    font-size: 11px;
  }
  .location_icon_1 {
    width: 15px;
    height: 15px;
  }

  .continue_to_payment {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main_booking_summary {
    padding: 30px;
    /* width: 90%; */
  }

  .your_services_main_booking_summary {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }
  .cleaner_name_service {
    display: flex;
    justify-content: center;
  }
  .heading_booking_summary{
    font-size: 18px;
  }
}
