* {
  font-family: Arial, Helvetica, sans-serif;
}

.img {
  width: 25px;
  height: 25px;
}

.img_wa_icon {
  width: 28px;
  height: 28px;
}

.social_icons {
  width: 40px;
  height: 40px;
}

.font_weight_adjustment {
  font-weight: 600;
}

.footer_main {
  background-color: #ffffff;
  position: relative;
  height: inherit;
}

.link_ref {
  text-decoration: none;
  color: black;
}

.btn_footer_link {
  background-color: black;
  color: white;
  border-radius: 4px;
  border: none;
  margin: 5px;
  font-size: 10px;
  width: 120px;
  padding: 4px;
}

.apple__footer_image {
  width: 30px;
}

.find_us {
  display: flex;
  justify-content: flex-end;
}

.row {
  display: flex;
}

a {
  font-weight: 700;
}

.contact_link_footer {
  font-size: small;
  margin-bottom: 5px !important;
}

.footer_content {
  margin-left: 20px;
}

@media screen and (max-width: 992px) {
  .find_us {
    justify-content: center !important    ;
  }

  .contact_link_footer {
    text-align: center;
  }

  .footer_content {
    margin-left: 0px;
    text-align: center;
  }
  
  h5 {
    text-align: center;
  }

  .footer_social_icons {
    margin: 0px 60px;
  }

  .footer_cell {
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .btn_store {
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer_social_icons {
    margin: 0;
  }
}
