* {
  font-family: Arial, Helvetica, sans-serif;
}

.hmpg {
  width: 100%;
}

.logo_img {
  width: 70%;
  margin-bottom: 20px;
}
.homePage_logo_section {
  align-items: center;
  min-height: 90vh;
  padding: 32px 3px;
  background-color: white;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.btn_link {
  background-color: black;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 5px;
  margin: 5px;
  width: 181px;
}

.apple_image {
  width: 50px;
}

.link_heading {
  font-size: 18px;
}
.get_app_hp {
  font-size: 20px;
}
@media screen and (max-width: 1340px) {
  .get_app_hp {
    font-size: 17px;
  }
  .btn_link {
    width: 169px;
  }
  .btn_download_appStore {
    font-size: 13px;
  }
}
@media screen and (max-width: 1200px) {
  .get_app_hp {
    font-size: 15px;
  }
  .btn_link {
    width: 160px;
  }
  .btn_download_appStore {
    font-size: 12px;
  }
}
@media screen and (max-width: 991px) {
  .homePage_logo_section {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-radius: 8px;
  }
  .homePage_logo_section_main {
    margin: 0px 10px;
  }
}

@media screen and (max-width: 600px) {
  .logo_img-adj {
    width: 180px;
    height: 180px;
  }
  .get_app_hp {
    font-size: 20px;
  }
  .btn_download_appStore {
    font-size: 10px;
  }
  .btn_link {
    width: 140px;
  }
}
@media screen and (max-width: 425px) {
  .get_app_hp {
    font-size: 20px;
  }
  .apple_image {
    width: 38px;
    padding-top: 4px;
  }
  .btn_download_appStore {
    font-size: 9px;
  }
  .btn_link {
    width: 117px;
  }
}

@media screen and (max-width: 375px) {
  .get_app_hp {
    font-size: 9px;
  }
  .homePage_logo_section_main {
    margin: 0px 0px;
  }
}
@media screen and (max-width: 331px) {
  .get_app_hp {
    font-size: 8px;
  }
}
