.signup_main {
  width: 100%;
}

.register_heading {
  color: #fff;
  font-size: 23px;
  padding-bottom: 60px;
  font-weight: 600;
  cursor: pointer;
}

.signup_btn_next {
  background-color: #21277b;
  border: none;
  color: white;
  border-radius: 20px;
  margin-top: 70px !important;
}

.signup_label_input {
  color: white;
  text-align: left;
}

.selected-auth-heading {
  color: #21277b !important;
  text-decoration: underline;
}

.reset_password_heading {
  color: white;
  font-weight: 600;
}

.btn_fgt_pswd{
  background-color: transparent;
  border : none;
  color: #21277B;
  text-decoration: none;
}
.btn_fgt_pswd:hover{
  text-decoration: underline;
}

.admin_login_heading {
  color: white;
}
.placeholders_login {
  color: #837195;
  font-weight: 600;
  padding: 10px 20px !important;
}
.placeholders_signup {
  color: #837195;
  font-weight: 600;
}

label {
  font-size: 15px;
}

.input_signup {
  width: 500px;
  height: 40px;
  border: 1px solid rgba(66, 66, 66, 0.5);
  border-radius: 8px;
  padding: 0px 10px;
}

.signup_main {
  position: relative;
  min-height: 100vh;
}

.signup_sub_main {
  min-height: calc(100vh - 150px);
  box-sizing: border-box;
}

.footer_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.signup_btn_next01 {
  background-color: #21277b;
  border: none;
  color: white;
  border-radius: 20px;
  margin-top: 40px !important;
}

.signup_label_input {
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .login_box_main,
  .signup_box_main {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media screen and (max-width: 550px) {
  .signup_label_input {
    margin-top: 30px;
  }

  .input_signup {
    width: 300px;
  }
}

@media screen and (max-width: 420px) {
  .signup_label_input {
    margin-top: 20px;
  }

  .input_signup {
    width: 15rem;
  }
}
