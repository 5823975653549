* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.add_cleaner_main {
  background-color: #f8f9fa;
  padding-bottom: 30px;
  border-radius: 10px;
}

.add_cleaner_main2 {
  background-color: #f8f9fa;
  padding-bottom: 30px;
  border-radius: 10px;
  height: 93vh;
}

.detail_box_admin {
  background-color: white;
  border-radius: 10px;
}

.cleaner_img_admin {
  width: 90px;
  height: 90px;
}

.img_title {
  color: #a080e1;
  font-size: 14px;
}

.name {
  width: 90%;
  height: 35px;
  border: 1px solid #b3afaf;
  border-radius: 5px;
}

.phone_number {
  width: 100%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
}

.input-field1 {
  padding-left: 40px;
  width: 100%;
  height: 35px !important;
  border: 1px solid #b3afaf;
}

.input-field2 {
  width: 100%;
  height: 35px !important;
  border: 1px solid #b3afaf;
}

.add_admin_cleaner {
  border: none;
  border-radius: 12px;
  background-color: #a080e1;
  color: white;
  font-size: 15px;
  padding: 1px 19px;
}

.todays_booking_box2 {
  background-color: #f6f9f8;
  height: 93vh;
  border-radius: 12px;
  padding: 0 !important;
}

.t_b_heading_db2 {
  font-size: 18px;
}

.btn_cleaners_seeAll {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.today_cleaners_main {
  overflow-y: auto;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.today_cleaners_main::-webkit-scrollbar {
  width: 0px;
}

.today_cleaners_main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.today_cleaners_main::-webkit-scrollbar-track {
  background-color: transparent;
}

.add_cleaner_link {
  font-size: 23px;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.detail_box_admin {
  padding-bottom: 40px;
}

.btn_add_admin {
  margin-top: 40px;
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .add_cleaners_name {
    display: block !important;
  }

  .name {
    width: 100%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 425px) {
  .detail_box_admin {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
