.your_service_main {
  margin-bottom: 90px !important;
  padding: 0px 30px !important;
}

.your_services_main_find_cleaner {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  min-height: auto;
}

.section_select {
  margin-top: 30px !important;
}

.available_cleaners {
  display: flex !important;
  padding-left: 15px;
}

.btn_book_calendar {
  color: white;
  background-color: #a080e1;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  width: 71px;
  height: 26px;
}

.first_hr {
  border: 1px solid #000000 !important;
}

.first_hr {
  padding: 0 !important;
  margin-top: 3px !important;
}

.available_cleaner_detail_box {
  background-color: white;
  border-radius: 8px;
  padding: 5px 5px;
  width: 100%;
}
.display_img {
  border: 2px solid #a080e1;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
.display_img_box {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 10px;
}
.price_tag {
  color: #a080e1;
  font-size: 24px;
}
.btn_select {
  background-color: #a080e1;
  color: white;
  border: none;
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 5px;
}

.avail_cleaner {
  color: #837195;
  font-size: 14px;
  margin-left: 5px;
}

.display_time {
  color: #a080e1;
  background-color: transparent;
  border: 1px solid #d2ebb5;
  border-radius: 5px;
  padding: 2px 5px;
  width: 80px;
}

.hidden {
  overflow: hidden;
}

.scrollable-row {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollable-row::-webkit-scrollbar {
  display: none;
}
.book_appointent_heading {
  font-size: 24px;
}

.box {
  flex: 0 0 auto;
  width: 400px;
  padding: 0 5px;
  box-sizing: border-box;
  border: 1px solid #837195;
  background-color: white;
  border-radius: 8px;
  margin-right: 20px;
}
.location_user {
  font-size: 12px;
}
.location_user {
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  .book_appointent_heading {
    font-size: 20px;
  }
  .location_user {
    font-size: 12px;
  }
  .appointment_booking_box {
    padding: 0px !important;
  }
  .btn_book_calendar {
    border-radius: 12px;
    padding: 2px 14px;
    font-size: 12px;
  }
  .calendar_compt_box {
    padding: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .your_services_main_find_cleaner {
    width: 100%;
  }
  .calendar_compt_box {
    padding: 0px !important;
  }
  .section_select {
    margin-top: 40px !important;
  }
  .book_appointent_heading {
    margin-left: 20px;
  }
  .location {
    margin-left: 20px;
  }
  .your_service_main {
    margin-bottom: 30px !important;
  }
  .btn_book_calendar {
    margin-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .scrollable-row {
    width: 95%;
  }
  .availability_heading_cleaner {
    font-size: 18px;
    margin-left: 10px;
  }
  .booking_appointment_heading {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .calendar_compt_box {
    padding: 0px !important;
  }
}
@media screen and (max-width: 425px) {
  .calendar_compt_box {
    padding: 0px !important;
  }
  .find_box_cleaner_main {
    padding: 0px;
  }
  .book_appointent_heading {
    font-size: 18px;
  }
}
@media screen and (max-width: 320px) {
  .your_service_main {
    margin-bottom: 30px !important;
  }
}
