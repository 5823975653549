.addCleaner_list {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 94vh;
 
}
.add_services_list_admin_dashboard{
  height: 85vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}



.add_services_list_admin_dashboard::-webkit-scrollbar {
  width: 0px;
}

.add_services_list_admin_dashboard::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.add_services_list_admin_dashboard::-webkit-scrollbar-track {
  background-color: transparent;
}