* {
  font-family: Arial, Helvetica, sans-serif;
}

.logo_image {
  width: 45px;
  height: 50px;
  margin-right: 90px;
}

.custom_btn {
  border: none;
  background: transparent;
}

.anchers {
  text-decoration: none;
  color: #2b2e28;
  margin-top: 8px;
  margin-right: 40px;
  font-weight: 600;
}

.anchers_signup_navbar {
  text-decoration: none;
  color: #2b2e28;
  margin-top: 8px;
  margin-right: 40px;
  font-weight: 600;
}

.anchers_signup_navbar:active,
.anchers_signup_navbar:focus {
  color: #a080e1;
}

.anchers:active,
.anchers:focus {
  color: #a080e1;
}

.home_link {
  font-weight: 100;
  font-size: 16px;
  color: white;
}

.nav_logo {
  width: 60px;
  height: 60px;
}

.wrapper {
  padding: 5px 20px;
}

.navbar {
  padding-right: 0 !important;
  margin-right: 0 !important;
}

@media screen and (max-width: 1112px) {
  .anchers_signup_navbar {
    margin-right: 27px;
  }
}

@media screen and (max-width: 1035px) {
  .anchers_signup_navbar {
    margin-right: 11px;
  }
}

@media screen and (max-width: 448px) {
  .anchers_signup_navbar {
    width: 70px;
  }
}
