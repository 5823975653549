.your_service_main01 {
  margin-bottom: 20px !important;
  padding: 1rem;
  border-radius: 8px;
  min-height: 87vh;
}

.your_services_main {
  background-color: #f6f9f8;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
}

.service-card-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section_select {
  margin-top: 80px !important;
}

.allow_access {
  border: none;
  color: #4988e1;
  background-color: transparent;
}

.allow_location_heading {
  font-size: 17px;
}

.location_link {
  text-decoration: none;
  color: black;
  font-size: 13px;
}

.hr_location {
  margin: 0;
  color: rgb(126, 126, 126);
}

.enter_manually {
  text-decoration: none;
  color: #a080e1;
  font-weight: 200;
}
@media screen and (max-width: 1024px) {
  .service_card_loged_in {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .your_services_main {
    padding: 0px !important;
    width: 70%;
  }
  .your_service_main {
    padding: 0px !important;
  }

  .section_select {
    margin-top: 40px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .your_services_main {
    width: 100%;
  }

  .your_service_main {
    margin-bottom: 30px !important;
  }

  .your_services_main {
    background-color: #f6f9f8;
    border-radius: 8px;
    padding: 0px;
    width: 100%;
  }
}
