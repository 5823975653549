.about_us {
  padding-top: 60px;
}

.app_logo {
  height: 100px;
  width: 95px;
}

.logo_heading {
  font-weight: 700;
  font-size: 55px;
  color: white;
}

.app_logo_box {
  display: flex;
  justify-content: flex-end;
}

.social_media_logo {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.description {
  padding: 90px 0px;
}
.description_aboutUs_details{
  color: white;
}

@media screen and (max-width: 768px) {
  .logo_heading {
    font-weight: 600;
    font-size: 40px;
  }

  .app_logo {
    height: 80px;
    width: 75px;
  }
}
@media screen and (max-width: 425px) {
  .app_logo_box {
    justify-content: center;
  }

  .logo_heading {
    text-align: center;
  }

  .social_media_links_box {
    text-align: center;
  }

  .description {
    padding-bottom: 40px;
    
      padding: 30px 0px;
   
  }
  .description_aboutUs_details{
    font-size: 14px;
  }
}
@media screen and (max-width: 320px) {
  .logo_heading {
    font-size: 35px;
  }
}
