.icon_nav_img {
  width: 30px;
  height: 30px;
}
.logout_icon_admin{
  width: 30px;
  height: 30px;
}

.main_sideBar_dashboard {
  padding: 0;
}

.outlet_style {
  background-image: linear-gradient(to bottom, #2940A6, #93e8ff);

  padding: 0;
}

.error_box_main {
  min-height: 90vh;
  background-image: linear-gradient(to bottom, #2940A6, #93e8ff);
}
.bg_change{
  background-image: linear-gradient(to bottom, #2940A6, #93e8ff);

}


.oops {
  font-size: 8rem;
}

@media screen and (max-width: 850px) {
  .oops {
    font-size: 6rem;
  }

  .error_content {
    font-size: 14px;
  }

  .main_content_error {
    padding-left: 30px;
  }
}

@media screen and (max-width: 500px) {
  .oops {
    font-size: 4rem;
  }
  
  .error_content {
    font-size: 14px;
  }
}
