* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.cleaner_availablity_card_main {
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 94vh;
  position: relative;
}

.cleaner_admin_profile_dashboard {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #a080e1;
}

.availability_cleaner_dashbaord01 {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.admin_head_cleaner {
  padding: 0;
  margin: 0;
  color: #a080e1;
}

.cleaner_availablity_card_sub_main {
  background-color: white;
  min-height: 92vh;
  padding: 1rem;
  border-radius: 8px;
}

.availability_cleaner_dashbaord {
  font-size: 12px;
  color: #b1dd7e;
  padding: 0;
  margin: 0;
}

.admin_service_title {
  color: #837195;
}

.details_icon {
  width: 18px;
  height: 18px;
}

.details_cleaner_icon {
  font-size: 14px;
  color: #837195;
}

.details_icon01 {
  width: 15px;
  height: 20px;
}

.bold {
  color: #a080e1;
  font-size: 16px;
}

.onSite_admin {
  color: black;
  background-color: #d2ebb5;
  padding: 2px 10px;
  border-radius: 4px;
}

.hr_card {
  border: 1px solid #a2f544;
}

.contact_info_admin {
  color: #a080e1;
}

.phone_number_admin {
  width: 20px;
  height: 20px;
}

.phone_mail {
  font-size: 14px;
  font-weight: 600;
}

.remove_cleaner {
  background-color: #a080e1;
  color: white;
  border-radius: 14px;
  padding: 3px 16px;
  border: none;
  font-size: 14px;
  position: absolute;
  bottom: 60px;
  width: 40% !important;
}

@media screen and (max-width: 1300px) {
  .details_cleaner_icon {
    font-size: 12px;
  }

  .bold {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .details_cleaner_icon {
    font-size: 11px;
  }

  .bold {
    font-size: 12px;
  }
}

@media screen and (max-width: 1150px) {
  .cleaner_admin_profile_dashboard {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 1080px) {
  .details_cleaner_icon {
    font-size: 10px;
  }

  .bold {
    font-size: 10px;
  }

  .details_icon {
    width: 13px;
    height: 13px;
  }

  .details_icon01 {
    width: 10px;
    height: 15px;
  }
}

@media screen and (max-width: 1050px) {
  .admin_head_cleaner {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .admin_head_cleaner {
    font-size: 22px;
  }

  .cleaner_admin_profile_dashboard {
    width: 60px;
    height: 60px;
  }

  .cleaner_availablity_card_main {
    margin-bottom: 20px;
    height: auto;
  }
  .cleaner_availablity_card_sub_main {
    min-height: 54vh;
  }

  .toogle_disable {
    display: flex;
    justify-content: center;
  }

  .details_icon {
    width: 18px;
    height: 18px;
  }

  .details_icon01 {
    width: 15px;
    height: 20px;
  }

  .bold {
    color: #a080e1;
    font-size: 16px;
  }

  .details_cleaner_icon {
    font-size: 14px;
    color: #837195;
  }
}

@media screen and (max-width: 575px) {
  .availability_cleaner_dashbaord {
    display: flex;
    justify-content: center;
  }

  .admin_head_cleaner_box {
    justify-content: center;
  }

  .remove_cleaner {
    bottom: 20px;
  }
}

@media screen and (max-width: 470px) {
  .details_cleaner_icon {
    font-size: 10px;
  }
  .cleaner_availablity_card_main {
    margin-bottom: 20px;
    height: auto;
  }
  .bold {
    font-size: 14px;
  }

  .flx_block {
    display: block !important;
  }

  .details_icon {
    width: 13px;
    height: 13px;
  }

  .details_icon01 {
    width: 10px;
    height: 15px;
  }

  .admin_service_title {
    font-size: 14px;
  }

  .contact_info_admin {
    font-size: 14px;
  }

  .cleaner_availablity_card_sub_main {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 54vh;
  }

  .remove_cleaner {
    font-size: 11px !important;
  }

  .phone_mail {
    font-size: 12px;
  }
}
